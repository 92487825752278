
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '../../../lib/abpbase'
import Cookies from 'js-cookie'
@Component
export default class UnLock extends AbpBase {
  name: string = 'Unlock'
  avatorLeft: string = '0px'
  inputLeft: string = '400px'
  password: string = ''
  check = null
  @Prop({ type: Boolean, default: false }) showUnlock: boolean
  get avatorPath() {
    return localStorage.avatorImgPath
  }
  async validator() {
    let loginModel = {
      userNameOrEmailAddress: this.$store.state.session.user.userName,
      password: this.password,
      rememberMe: false
    }
    if (sessionStorage.getItem('rememberMe') === '1') {
      loginModel.rememberMe = true
    }
    await this.$store.dispatch({
      type: 'app/login',
      data: loginModel
    })
    return true
  }
  handleClickAvator() {
    this.avatorLeft = '-180px'
    this.inputLeft = '0px'
    ;(this.$refs.inputEle as any).focus()
  }
  async handleUnlock() {
    if (await this.validator()) {
      this.avatorLeft = '0px'
      this.inputLeft = '400px'
      this.password = ''
      Cookies.set('locking', '0')
      this.$emit('on-unlock')
    } else {
      //this.$Message.error("WrongPassword,Wrong password");
    }
  }
  unlockMousedown() {
    ;(this.$refs.unlockBtn as any).className = 'unlock-btn click-unlock-btn'
  }
  unlockMouseup() {
    ;(this.$refs.unlockBtn as any).className = 'unlock-btn'
  }
}
